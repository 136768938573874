<template>
  <div class="stock text-left">
    <h1>Stock trading hours</h1>
    <h3>Check whether your exchange is Open in list below</h3>
    <p>
      'Current time' column reflects weekends, holidays and special cases when
      the exchange is closed.
    </p>
    <br />
    <StockList />
  </div>
</template>

<script>
// @ is an alias to /src
import StockList from "@/components/StockList.vue";

export default {
  name: "Stock",
  components: {
    StockList
  }
};
</script>
