<template>
  <v-app>
    <div id="list" class="list">
      <v-data-table
        :headers="headers"
        :items="list"
        :items-per-page="50"
        :item-class="rowClass"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        hide-default-footer
        class="stock-exchanges exchanges"
      >
        <template v-slot:item.shortname="{ item }">
          <div class="name">
            <h2>{{ item.shortname }}</h2>
            <h6>({{ item.name }})</h6>
          </div>
        </template>
        <template v-slot:item.open_local="{ item }">
          {{ item.open_local }} - {{ item.close_local }} ({{ item.time_zone }})
        </template>
        <template v-slot:item.now="{ item }">
          <div class="timecell">
            <v-chip
              :class="[item.open ? 'primary' : '']"
              :title="[item.holiday ? item.holiday : '']"
              dark
            >
              <v-icon dark>
                mdi-clock-time-five-outline
              </v-icon>
              {{ "&nbsp;" }}
              {{ item.open ? " Open" : " Closed" }} {{ item.time }}:{{ seconds }}
              {{ item.holiday ? "*" : "" }}
            </v-chip>
          </div>
        </template>
        <template v-slot:item.tools="">
          <div class="tools">
            <v-btn
              color="success"
              href="https://etoro.tw/2JcoTZC"
              target="_blank"
              rel="noopener"
              name="eToro"
              small
            >
              <v-icon dark>
                mdi-shopping-outline
              </v-icon>
              eToro
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </div>
  </v-app>
</template>

<script>
import exchanges from "../data/exchanges-stock.json";
import axios from "axios";

var apiUrl = window.location.protocol + "//api." + window.location.hostname;
apiUrl = apiUrl.replace("www.", "");

// TimeZones
var seconds = "00";

// Language for what to show the time
var lang = navigator.language;
if (navigator.languages != undefined) {
  lang = navigator.languages[0];
}

var holidays = {};

export default {
  name: "ListComponent",
  data: function() {
    return {
      sortBy: "rank",
      sortDesc: false,
      search: "",
      headers: [
        {
          text: "#",
          value: "rank"
        },
        {
          text: "Exchange",
          value: "shortname"
        },
        {
          text: "Place",
          value: "market_place"
        },
        {
          text: "Open Hours",
          value: "open_local"
        },
        {
          text: "Current time",
          align: "center",
          value: "now"
        },
        {
          text: "Go trade",
          align: "center",
          value: "tools"
        }
      ],
      list: Object.values(exchanges),
      seconds: seconds,
      holidays: holidays
    };
  },
  created() {
    setInterval(this.getSeconds, 1000);

    var getMinute = this.getMinute;
    this.getHolidays(getMinute);
  },
  methods: {
    rowClass: function() {
      return "";
    },
    getSeconds: function() {
      seconds = "0" + new Date().getSeconds();
      this.seconds = seconds.slice(-2);

      if (this.seconds === "00") {
        this.getMinute();
      }
    },
    getMinute: function() {
      for (const item of Object.entries(this.list)) {
        // Get exchange local time
        var timezoneDatetime = new Date().toLocaleString(lang, {
          timeZone: item[1]["timeZone"]
        });
        var dateString = timezoneDatetime.split(", ")[0];
        var timeString = timezoneDatetime.split(", ")[1];
        var timeParts = timeString.split(":");
        item[1]["time"] = timeParts[0] + ":" + timeParts[1];

        var timeOpen = Date.parse(
          "2020/01/01 " + item[1]["open_local"] + ":00"
        );
        var timeClose = Date.parse(
          "2020/01/01 " + item[1]["close_local"] + ":00"
        );
        var time = Date.parse("2020/01/01 " + timeString);

        // Check if open
        var open = true;
        var holiday = false;
        if (timeOpen >= time) {
          open = false;
        }

        if (timeClose <= time) {
          open = false;
        }

        if (
          holidays[item[1]["shortname"]] &&
          holidays[item[1]["shortname"]]["date"] === dateString
        ) {
          holiday = holidays[item[1]["shortname"]]["name"];
          open = false;
        }

        item[1]["open"] = open;
        item[1]["holiday"] = holiday;
        item[1]["tools"] = "";

        if (open) {
          item[1]["now"] = "1first";
        } else {
          item[1]["now"] = "2second";
        }
      }
    },

    getHolidays(callback) {
      // Holiday preset
      axios
        .get(apiUrl + "/holidays.php")
        .then(function(response) {
          holidays = response.data;

          callback();
        })
        .catch(function(error) {
          console.log(error);
        });
    }
  }
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "~@/scss/list.scss";
</style>
